<template>
  <div>
    <loader></loader>
  </div>
</template>

<script>
  export default {
    name: "signLink",
    components:{
      loader:() => import('@/components/other/loader'),
    },
    mounted(){
      let params = this.$route.query;
      console.log(params);
      if (params['confirmationid'] !== undefined) {
        this.$external.universalRequest({
          externalType: 'zita',
          action: 'CheckSmsLink',
          GUID: params['confirmationid'],
        })
        .then((res) => {
          this.$store.commit('setUserID', res.UserID);
          this.$store.commit('setSessionKey', res.SessionKey);
          if(res.demand !== undefined
              && typeof res.demand == 'object'
              && res.demand.canrenewal === 1) {
            this.$store.dispatch('updateContentData').then(()=>{
              this.$router.push('/pdl/prolongation');
            }).catch((res)=>{
              alert(res)
            });
          } else {
            this.$router.push('/');
          }
        }).catch((res)=>{
          if ( res.errorCode > 0 || res.errorCode !== undefined ) {
            alert('Ошибка' + ' ' + res.errorCode + ': ' + res.errorMsg)
          }
        });
      } else {
        alert('не корректная ссылка');
      }
    }
  }
</script>

<style scoped>

</style>
